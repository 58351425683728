<template>
  <div
    class="min-w-screen min-h-screen bg-secondary flex items-center justify-center px-5 py-5"
  >
    <div
      class="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
      style="max-width: 1000px"
    >
      <div class="md:flex w-full">
        <div
            class="hidden imageDivClass md:flex w-1/2 bg-cover"
        >
          <img class="hidden md:flex bg-cover" :src="backgroundSignIn" v-if="backgroundSignIn && !loading" />
          <img
              :alt="title"
              class="hidden md:flex bg-cover"
              src="/the-one-stellenbosch/images/login.jpg"
              v-if="!backgroundSignIn && !loading"
          />
          <PageLogo />
        </div>
        <div class="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div class="text-center mb-5">
            <ToggleSwitch/>
            <p>Login to view the {{title}} Price List &amp; Plans</p>
          </div>

          <form @submit.prevent="submit" @keyup.enter.prevent="submit">
            <div class="flex -mx-3">
              <div class="w-full px-3 mb-2">
                <label for="login-email" class="text-xs font-semibold px-1">Email</label>
                <div class="flex">
                  <div
                    class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                  >
                    <EmailIcon />
                  </div>
                  <input
                    v-model="form.email"
                    id="login-email"
                    type="text"
                    name="email"
                    class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary-ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                    placeholder=""
                  />
                </div>
                <span
                class="text-xs text-red-600"
                :class="{ '': $v.form.email.$error, 'invisible': !$v.form.email.$error }">
                  Please enter a valid email address</span>
              </div>
            </div>

            <div class="flex -mx-3">
              <div class="w-full px-3 mb-2">
                <label for="login-password" class="lb-icon-text-label"
                  >Password</label
                >
                <div class="flex">
                  <div
                    class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                  >
                    <PasswordIcon />
                  </div>
                  <input
                    v-if="showPassword"
                    v-model="form.password"
                    id="login-password"
                    type="text"
                    name="password"
                    class="password-content"
                    placeholder=""
                  />

                  <input
                    v-else
                    v-model="form.password"
                    name="password"
                    type="password"
                    class="password-content"
                    placeholder=""
                  />
                  <div
                    class="password-shower"
                    @click="toggleShow"
                  >
                    <span >
                      <i v-if="showPassword" class="password-showerBtn"><EyeOutlineIcon/></i>
                      <i v-if="!showPassword" class="password-showerBtn"><EyeOffOutlineIcon/></i>
                    </span>
                  </div>

                </div>
                <span
                class="text-xs text-red-600"
                :class="{ '': $v.form.password.$error, 'invisible': !$v.form.password.$error }">
                  Please enter a valid password. Must include more than 7 characters</span>
              </div>


            </div>

            <div class="flex -mx-3 mt-5">
              <div class="w-full px-3 mb-5">
                <button
                  class="block w-full max-w-xs mx-auto bg-primary hover:bg-primary focus:bg-primary text-white rounded-lg px-3 py-3 font-semibold"
                  type="submit"
                >
                  LOGIN
                </button>

                <router-link
                class="block mt-5 text-center text-sm transform hover:scale-110"
                :to="{name: 'register' }"
                >
                  Need an account? Register.
                </router-link>

                <router-link
                class="block mt-5 text-center text-sm transform hover:scale-110"
                :to="{name: 'reset-password'}">Forgot your password?</router-link>
              </div>
            </div>
          </form>


      </div>
    </div>
  </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import {required, email, minLength} from "vuelidate/lib/validators"
import EmailIcon from 'vue-material-design-icons/At'
import PasswordIcon from 'vue-material-design-icons/Lock'
import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline'
import EyeOffOutlineIcon from 'vue-material-design-icons/EyeOffOutline'
import PageLogo from '@/components/app/PageLogo.vue';
import {APP_TITLE} from '../../Constants.js'
import ToggleSwitch from "@/components/ToggleSwitch";
import {settingsCollection} from "@/firebase";

export default {
  name: 'login',
  components: {
    ToggleSwitch,
    EmailIcon,
    PasswordIcon,
    EyeOutlineIcon,
    EyeOffOutlineIcon,
    PageLogo
  },
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    title: APP_TITLE,
    backgroundSignIn: null,
    logoSignIn: null,
    loading: true,
    showPassword: false,
  }),
  validations: {
    form: {
      email: {required, email},
      password: {required, minLength: minLength(7)},
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    submit() {
      this.$v.form.$touch()
      if(this.$v.form.$error) return

      firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
      .then((userCredential) => {
        console.log(userCredential)
        this.$router.replace({name: 'home'})
      })
      .catch((error) => {
        if(error.code=== "auth/user-not-found"){ 
          this.$notify({
          group: 'global',
          type: 'error',
          title: 'Login error',
          text: "Sorry, we couldn't find an account with that username, please register to create an account."
          })}
        else{
          this.$notify({
          group: 'global',
          type: 'error',
          title: 'Login error',
          text: error.message  
        });
        }
      });
    },
  },
  created() {
    settingsCollection.doc("globalSettings").onSnapshot((doc) => {
      this.backgroundSignIn = doc.data().backgroundSignIn;
      this.logoSignIn = doc.data().logoSignIn;
      this.loading = false;
    });
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
}
</script>

<style>
.showPassword-container {
  width: 10%;
  height: 50px;
}
.imageDivClass {
  position: relative;
}
.password-content {
  width: 90%;
  margin-left: -2.5rem;
  padding: .5rem .75rem .5rem 2.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  border-radius: .25rem 0 0 .25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.password-content:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 148, 97, var(--tw-ring-opacity));
}

.password-shower {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  border-radius: 0 .25rem .25rem 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.password-shower:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 148, 97, var(--tw-ring-opacity));
}

.password-showerBtn {
  display: flex;
  justify-content: center;
  align-content: center;
}

.signIn-logo {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
}

</style>
